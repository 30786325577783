<template>
  <d-virtual-table
    :grid="grid"
    :columns="columns"
    :items="items"
    selection="multiple"
    with-action-btns
    :selected="selected"
    @update:selected="$emit('update:selected', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        :class="btnClass(true)"
        icon="mdi-pencil-outline"
        color="primary"
        label="Create Refill method"
        :to="{ name: 'RefillMethodsCreate' }"
      />
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <d-row-actions
          :item="props"
          page-name="RefillMethodsEdit"
        />
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card :item="props" />
    </template>
  </d-virtual-table>
</template>

<script>
import { tableColumns as columns } from './config';
import { DGridCard } from './features';
import { DRowActions } from '@/features/row-actions';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';

export default {
  components: {
    DGridCard,
    DVirtualTable,
    DRowActions,
  },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disableRemoveBtn: {
      type: Boolean,
      default: () => false,
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    columns,
  }),
};
</script>
