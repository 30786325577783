<template>
  <d-view-card
    ref="view"
    :remove-function="refillMethodController.removeRefillMethod"
    :fetch-function="refillMethodController.getRefillMethods"
    remove-confirm-title="Are you sure you want to delete this refill method(s)"
    remove-success-title="Refill(s) method successfully removed"
  >
    <template #default="{ loading, find, selectedData, removeHandler, data }">
      <div class="col-grow">
        <div class="q-pa-md">
          <d-refill-method-table
            :items="data"
            :disable-remove-btn="!selectedData.length"
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
            @on-remove="removeHandler"
            :grid="$q.screen.lt.md"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DRefillMethodTable } from '@/features/refill-methods-table';
import DViewCard from '@/layouts/view-card';
import { refillMethodController as apiController } from '@/shared/api';

export default {
  components: { DViewCard, DRefillMethodTable },
  data: () => ({
    refillMethodController: apiController(),
  }),
};
</script>
