import { render, staticRenderFns } from "./grid-card.vue?vue&type=template&id=48595bc0&"
import script from "./grid-card.vue?vue&type=script&lang=js&"
export * from "./grid-card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardSection,QCheckbox,QSeparator,QBtnGroup});
